export const maskCPF = (cpf) => {
  return cpf
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const maskCNPJ = (cnpj) => {
  return cnpj
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const cepMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};

export const maskDate = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};

export const phoneMask = (value) => {
  if (!value) return null;

  if (value.replace(/[^0-9]/g, '').length > 10) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{0})(\d)/, '$1($2')
      .replace(/(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }

  return value
    .replace(/\D/g, '')
    .replace(/(\d{0})(\d)/, '$1($2')
    .replace(/(\d{2})(\d)/, '$1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
};

export const maskDocument = (document) => {
  document = document.replace(/[^0-9]/g, '');

  if (document.length > 11) {
    return maskCNPJ(document);
  }

  return maskCPF(document);
};

export const moneyMask = (value) => {
  let valor = value;
  valor += '';
  valor = valor.replace(/[\D]+/g, '');
  valor += '';
  valor = valor.replace(/([0-9]{2})$/g, ',$1');

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
  }

  return valor;
};

export const maskOnlyNumbers = (value) => {
  return value.replace(/\D/g, "");
};

export const maskOnlyLetters = (value) => {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, "");
};

export const maskDateTime = (value)=>{
  let  data = new Date(value),
  dia  = data.getDate().toString(),
  diaF = (dia.length == 1) ? '0'+dia : dia,
  mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
  mesF = (mes.length == 1) ? '0'+mes : mes,
  anoF = data.getFullYear(),
  hora = data.toLocaleTimeString();

  return `${diaF}/${mesF}/${anoF} ${hora}`;
}
