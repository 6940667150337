import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  createBenefitService,
  getByIdBenefitService,
  updateBenefitService,
} from "../../../services/Register/BenefitService";

const FormBenefitComponentView = ({
  isModal = false,
  handleActionAfterSave = () => {},
}) => {
  let params = useParams();
  if (isModal) {
    params = {};
  }
  const [register, setRegister] = useState({
    id: null,
    title: null,
    description: null,
  });

  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleInsert();
    handleUpdate();
  };

  const handleUpdate = () => {
    if (!params?.id || params?.id <= 0) return;
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    updateBenefitService(register.id, register)
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          path: null,
          description: null,
          whatIsDescription: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          handleActionAfterSave();
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleInsert = () => {
    if (params?.id && params?.id > 0) return;

    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    createBenefitService(register)
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          path: null,
          description: null,
          whatIsDescription: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          handleActionAfterSave();
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetById = () => {
    if (!params?.id || params?.id <= 0) {
      return;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getByIdBenefitService(params?.id)
      .then(({ data }) => {
        Swal.close();
        setRegister(data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleGetById();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card className="border-0 shadow">
          <CardHeader className="border-0 bg-white">
            <h3>Vantagem</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="name">Titulo</Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    value={register.title}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        title: ev.target.value,
                      });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="description">Descrição</Label>
                  <textarea
                    className="form-control"
                    type="text"
                    id="description"
                    name="description"
                    value={register.description}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        description: ev.target.value,
                      });
                    }}
                    required
                  ></textarea>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" className="mr-2">
              SALVAR
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
};

export default FormBenefitComponentView;
