const colors = [
  "primary-01",
  "primary-02",
  "primary-03",
  "secondary-01",
  "secondary-02",
  "secondary-03",
  "secondary-04",
  "violet-98",
  "violet-80",
  "violet-60",
  "violet-40",
  "violet-10",
  "ciano-98",
  "ciano-90",
  "ciano-70",
  "ciano-50",
  "ciano-20",
  "purple-40",
  "lemon-63",
  "blue-60",
  "yellow-72",
  "cherry-42",
];
export default colors;
