import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  generateNewTokenService,
  showTokenService,
} from "../../services/Register/TokenService";
import { useEffect } from "react";

const TokenApiView = () => {
  const [token, setToken] = useState("");
  const handleGetToken = () => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    showTokenService()
      .then(({ data }) => {
        Swal.close();
        setToken(data?.resultSet?.tokenApi ?? null);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGenerateNewToken = () => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Tem certeza que deseja alterar a chave de API?",
      showCancelButton: true,
      cancelButtonText: "Sair",
      confirmButtonText: "Sim, continuar",
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;
      Swal.fire({
        title: "Gerando novo token",
        text: "Por favor aguarde...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      generateNewTokenService()
        .then(({ data }) => {
          Swal.close();
          setToken(data?.resultSet);
        })
        .catch((err) => {
          const code = !err.response ? "00000" : err.response.data.code;
          const message = !err.response
            ? "Ocorreu um erro inesperado"
            : err.response.data.message;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  useEffect(() => {
    handleGetToken();
  }, []);

  return (
    <>
      <div>
        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            <Card className="border-0 mb-5 mt-3 shadow">
              <CardHeader className="border-0 bg-white">
                <h4 className="headline-2">TOKEN - API</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Token</Label>
                      <InputGroup>
                        <Input type="text" value={token} />
                        <InputGroupAddon addonType="prepend">
                          <Button onClick={handleGenerateNewToken}>
                            Gerar novo token
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TokenApiView;
