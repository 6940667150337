import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  CardFooter,
  Button,
  Form,
  Alert,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  createUserService,
  getByIdUserService,
  updateUserService,
} from "../../services/Register/UserService";
import BvIcon from "../../lib/bvIcon/index";

const UserRegisterView = () => {
  const history = useHistory();
  const params = useParams();
  const [register, setRegister] = useState({
    id: null,
    name: null,
    email: null,
    password: null,
    repeatPassword: null,
  });

  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleInsert();
    handleUpdate();
  };

  const handleUpdate = () => {
    if (!params?.id || params?.id <= 0) return;
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    updateUserService(register.id, register)
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          email: null,
          password: null,
          repeatPassword: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/usuario-sistema");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleInsert = () => {
    if (params?.id && params?.id > 0) return;

    if (register.password !== register.repeatPassword) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "As senhas informadas não conferem, por favor verificar!",
      });
      return;
    }

    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    createUserService(register)
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          email: null,
          password: null,
          repeatPassword: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/usuario-sistema");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetById = () => {
    if (!params?.id || params?.id <= 0) {
      return;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getByIdUserService(params?.id)
      .then(({ data }) => {
        Swal.close();
        setRegister({
          ...data.resultSet,
          password: null,
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleGetById();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card className="border-0 shadow">
          <CardHeader className="border-0 bg-white">
            <span className="text-danger float-right">
              Os campos com * são obrigatórios
            </span>
            <h3>{!register?.id ? `Novo usuário` : `Editar usuário`}</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="name">
                    Nome <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="nome"
                    name="nome"
                    value={register.name}
                    onChange={(ev) => {
                      setRegister({ ...register, name: ev.target.value });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="email">
                    E-mail <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="email"
                    id="email"
                    name="phoemailne"
                    autoComplete="new-password"
                    value={register.email}
                    onChange={(ev) => {
                      setRegister({ ...register, email: ev.target.value });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="password">
                    Senha{" "}
                    {!register?.id && <span className="text-danger">*</span>}
                  </Label>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    autoComplete="new-password"
                    value={register.password}
                    onChange={(ev) => {
                      setRegister({ ...register, password: ev.target.value });
                    }}
                    require={!register?.id}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="repeat-password">
                    Repetir senha{" "}
                    {!register?.id && <span className="text-danger">*</span>}
                  </Label>
                  <Input
                    type="password"
                    id="repeat-password"
                    autoComplete="new-password"
                    name="repeat-password"
                    value={register.repeatPassword}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        repeatPassword: ev.target.value,
                      });
                    }}
                    require={!register?.id}
                  />
                </FormGroup>
              </Col>
              {register?.id > 0 && (
                <Col md="12">
                  <Alert color="warning" className="text-warning">
                    <span className="font-weight-bold">Atenção:</span> Só
                    informar uma nova senha caso queira alterar a senha do
                    usuário
                  </Alert>
                </Col>
              )}
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" className="mr-2">
              SALVAR
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push("/admin/usuario-sistema")}
            >
              VOLTAR
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
};

export default UserRegisterView;
