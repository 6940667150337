import api from "../api";

export const createBannerHomeService = async (data) => {
  return await api.post("/api/v1/banner-home", data);
};

export const updateBannerHomeService = async (id, data) => {
  return await api.put(`/api/v1/banner-home/${id}`, data);
};

export const listBannerHomeService = async (data) => {
  return await api.get("/api/v1/banner-home", {
    params: data,
  });
};

export const getByIdBannerHomeService = async (id) => {
  return await api.get(`/api/v1/banner-home/${id}`);
};

export const inactiveActiveBannerHomeService = async (id) => {
  return await api.put(`/api/v1/banner-home/active-inactive/${id}`);
};
