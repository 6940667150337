import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  CardFooter,
  Button,
  Form,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  createBenefitService,
  getByIdBenefitService,
  updateBenefitService,
} from "../../services/Register/BenefitService";
import IconComponentView from "./component/IconComponentView";
import FormBenefitComponentView from "./component/FormBenefitComponentView";

const BenefitRegisterView = () => {
  const history = useHistory();
  const params = useParams();
  const [register, setRegister] = useState({
    id: null,
    title: null,
    description: null,
  });

  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleInsert();
    handleUpdate();
  };

  const handleUpdate = () => {
    if (!params?.id || params?.id <= 0) return;
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    updateBenefitService(register.id, register)
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          path: null,
          description: null,
          whatIsDescription: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/vantagem");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleInsert = () => {
    if (params?.id && params?.id > 0) return;

    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    createBenefitService(register)
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          path: null,
          description: null,
          whatIsDescription: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/vantagem");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetById = () => {
    if (!params?.id || params?.id <= 0) {
      return;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getByIdBenefitService(params?.id)
      .then(({ data }) => {
        Swal.close();
        setRegister(data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleGetById();
  }, []);

  return (
    <>
      <FormBenefitComponentView
        handleActionAfterSave={() => {
          history.push("/admin/vantagem");
        }}
      />
    </>
  );
};

export default BenefitRegisterView;
