import ellieSmithImg from "../../assets/tables/ellieSmithImg.png";
import floydMilesImg from "../../assets/tables/floydMilesImg.png";
import rosaFloresImg from "../../assets/tables/rosaFloresImg.png";
import janeCooperImg from "../../assets/tables/janeCooper.png";
import bagIcon from "../../assets/tables/bagIcon.svg";
import folderIcon from "../../assets/tables/folderIcon.svg";
import joystickIcon from "../../assets/tables/joystickIcon.svg";
import basketIcon from "../../assets/tables/basketIcon.svg";



const mock = {
  firstTable: [
    {
      id: "125as2df2e2f",
      name: "John Due",
      phone: "(44) 9999-6666",
      status: "Aguardando Envio",
      message: "Mensagem de envio....",
      color: "badge badge-warning"
    },
    {
      id: "125as2df2e2f",
      name: "John Due",
      phone: "(44) 9999-6666",
      status: "Aguardando Envio",
      message: "Mensagem de envio....",
      color: "badge badge-warning"
    },
    {
      id: "125as2df2e2f",
      name: "John Due",
      phone: "(44) 9999-6666",
      status: "Aguardando Envio",
      message: "Mensagem de envio....",
      color: "badge badge-info"
    },
    {
      id: "125as2df2e2f",
      name: "John Due",
      phone: "(44) 9999-6666",
      status: "Aguardando Envio",
      message: "Mensagem de envio....",
      color: "badge badge-warning"
    }
  ],
  secondTable: [
    {
      id: "checkbox211",
      name: "Jane Cooper",
      email: "jane.cooper@example.com",
      product: "series",
      price: "$5.22",
      date: "5/27/21",
      city: "Pembroke",
      color: "secondary-cyan",
      status: "Sent",
    },
    {
      id: "checkbox212",
      name: "Ellie Smith",
      email: "ellie.smith@example.com",
      product: "chair pad",
      price: "$53.99",
      date: "3/19/21",
      city: "Austin",
      color: "secondary-cyan",
      status: "Sent",
    },
    {
      id: "checkbox213",
      name: "Rosa Flores",
      email: "rosa.flores@example.com",
      product: "dishes",
      price: "$12.24",
      date: "4/01/21",
      city: "Naperville",
      color: "secondary-yellow",
      status: "Pending",
    },
    {
      id: "checkbox214",
      name: "Floyd Miles",
      email: "floyd.miles@example.com",
      product: "towels",
      price: "$8.99",
      date: "4/27/21",
      city: "Fairfield",
      color: "secondary-red",
      status: "Declined",
    },
    {
      id: "checkbox221",
      name: "Floyd Miles",
      email: "floyd.miles@example.com",
      product: "towels",
      price: "$8.99",
      date: "4/27/21",
      city: "Fairfield",
      color: "secondary-cyan",
      status: "Sent",
    },
    {
      id: "checkbox222",
      name: "Floyd Miles",
      email: "floyd.miles@example.com",
      product: "towels",
      price: "$8.99",
      date: "4/27/21",
      city: "Fairfield",
      color: "secondary-red",
      status: "Declined",
    },
    {
      id: "checkbox223",
      name: "Floyd Miles",
      email: "floyd.miles@example.com",
      product: "towels",
      price: "$8.99",
      date: "4/27/21",
      city: "Fairfield",
      color: "secondary-yellow",
      status: "Pending",
    },
    {
      id: "checkbox224",
      name: "Floyd Miles",
      email: "floyd.miles@example.com",
      product: "towels",
      price: "$8.99",
      date: "4/27/21",
      city: "Fairfield",
      color: "secondary-cyan",
      status: "Sent",
    },
    {
      id: "checkbox231",
      name: "Floyd Miles",
      email: "floyd.miles@example.com",
      product: "towels",
      price: "$8.99",
      date: "4/27/21",
      city: "Fairfield",
      color: "secondary-red",
      status: "Declined",
    },
    {
      id: "checkbox232",
      name: "Floyd Miles",
      email: "floyd.miles@example.com",
      product: "towels",
      price: "$8.99",
      date: "4/27/21",
      city: "Fairfield",
      color: "secondary-cyan",
      status: "Sent",
    },
    {
      id: "checkbox233",
      name: "Floyd Miles",
      email: "floyd.miles@example.com",
      product: "towels",
      price: "$8.99",
      date: "4/27/21",
      city: "Fairfield",
      color: "secondary-yellow",
      status: "Pending",
    },
    {
      id: "checkbox234",
      name: "Floyd Miles",
      email: "floyd.miles@example.com",
      product: "towels",
      price: "$8.99",
      date: "4/27/21",
      city: "Fairfield",
      color: "secondary-cyan",
      status: "Sent",
    },
    {
      id: "checkbox241",
      name: "Floyd Miles",
      email: "floyd.miles@example.com",
      product: "towels",
      price: "$8.99",
      date: "4/27/21",
      city: "Fairfield",
      color: "secondary-red",
      status: "Declined",
    },
    {
      id: "checkbox242",
      name: "Ellie Smith",
      email: "ellie.smith@example.com",
      product: "chair pad",
      price: "$53.99",
      date: "3/19/21",
      city: "Austin",
      color: "secondary-cyan",
      status: "Sent",
    },
    {
      id: "checkbox243",
      name: "Floyd Miles",
      email: "floyd.miles@example.com",
      product: "towels",
      price: "$8.99",
      date: "4/27/21",
      city: "Fairfield",
      color: "secondary-yellow",
      status: "Pending",
    },
    {
      id: "checkbox244",
      name: "Ellie Smith",
      email: "ellie.smith@example.com",
      product: "chair pad",
      price: "$53.99",
      date: "3/19/21",
      city: "Austin",
      color: "secondary-cyan",
      status: "Sent",
    },
  ],
  transactionsWidget: [
    {
      id: 1,
      icon: basketIcon,
      category: "Shopping",
      date: "05 Jun 2020 10:00",
      price: "$300",
      description: "Some text",
      dropdownOpen: false,
    },
    {
      id: 2,
      icon: joystickIcon,
      category: "Shopping",
      date: "05 Jun 2020 10:00",
      price: "$300",
      description: "Some text",
      dropdownOpen: false,
    },
    {
      id: 3,
      icon: folderIcon,
      category: "Shopping",
      date: "05 Jun 2020 10:00",
      price: "$300",
      description: "Some text",
      dropdownOpen: false,
    },
    {
      id: 4,
      icon: bagIcon,
      category: "Shopping",
      date: "05 Jun 2020 10:00",
      price: "$300",
      description: "Some text",
      dropdownOpen: false,
    }
  ],
  tasksWidget: [
    {
      id: 1,
      description: "Create An Image",
      time: "9 AM",
      completed: false,
    },
    {
      id: 2,
      description: "Team Design Miting",
      time: "11 AM",
      completed: false,
    },
    {
      id: 3,
      description: "Create An Image",
      time: "2.30 PM",
      completed: false,
    },
    {
      id: 4,
      description: "Interview With John Hamm",
      time: "4 PM",
      completed: false,
    },
  ]
}

export default mock;
