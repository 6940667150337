import api from "../api";

export const createQuestionService = async (bank) => {
  return await api.post("/api/v1/question", bank);
};

export const updateQuestionService = async (id, bank) => {
  return await api.put(`/api/v1/question/${id}`, bank);
};

export const listQuestionService = async (filter) => {
  return await api.get("/api/v1/question", {
    params: filter,
  });
};

export const getByIdQuestionService = async (id) => {
  return await api.get(`/api/v1/question/${id}`);
};

export const inactiveActiveQuestionService = async (id) => {
  return await api.put(`/api/v1/question/active-inactive/${id}`);
};
