import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import { changeActiveSidebarItem } from "../../actions/navigation.js";
import Logo from "../../assets/logo.svg";
import cn from "classnames";

const Sidebar = (props) => {
  const { activeItem = "", ...restProps } = props;

  const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false);

  const user = JSON.parse(localStorage.getItem("data_user_bevi_app"));

  console.log(user);

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerSidebarOpen(true);
    } else {
      setTimeout(() => {
        setBurgerSidebarOpen(false);
      }, 0);
    }
  }, [props.sidebarOpened]);

  return (
    <nav className={cn(s.root, { [s.sidebarOpen]: burgerSidebarOpen })}>
      <header className={s.logo}>
        <img src={Logo} alt="BEVI" />
      </header>
      <ul className={s.nav}>
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Banner Home"
          isHeader
          iconName={<i className={"eva eva-home-outline"} />}
          link="/admin/banner"
          index="Banner Home"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Filiais"
          isHeader
          iconName={<i className={"eva eva-briefcase-outline"} />}
          link="/admin/filial"
          index="Filiais"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Financeira"
          isHeader
          iconName={<i className={"eva eva-archive-outline"} />}
          link="/admin/financeira"
          index="Financeira"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Usuários de sistema"
          isHeader
          iconName={<i className={"eva eva-people-outline"} />}
          link="/admin/usuario-sistema"
          index="Usuários de sistema"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Categoria"
          isHeader
          iconName={<i className={"eva eva-grid-outline"} />}
          link="/admin/categoria"
          index="Categoria"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Produtos"
          isHeader
          iconName={<i className={"eva eva-keypad-outline"} />}
          link="/admin/produto"
          index="Produtos"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Vantagens"
          isHeader
          iconName={<i className={"eva eva-list-outline"} />}
          link="/admin/vantagem"
          index="Vantagens"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Dúvida"
          isHeader
          iconName={<i className={"eva eva-question-mark-outline"} />}
          link="/admin/duvida"
          index="Dúvida"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Notas oficiais"
          isHeader
          iconName={<i className={"eva eva-file-text-outline"} />}
          link="/admin/nota-oficial"
          index="Notas Oficiais"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="API"
          isHeader
          iconName={<i className={"eva eva-code-outline"} />}
          link="/admin/api"
          index="API"
        />

        {/* <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Chat"
          isHeader
          iconName={<i className={'fa fa-comment'}/>}
          link="/admin/chat"
          index="Chat"
        /> */}
        {
          /* <h5 className={s.navTitle}>TEMPLATE</h5>
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Typography"
          isHeader
          iconName={<i className={'eva eva-text-outline'}/>}
          link="/admin/typography"
          index="typography"
        />
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Tables"
          isHeader
          iconName={<i className={'eva eva-grid-outline'}/>}
          link="/admin/tables"
          index="tables"
        />
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Notifications"
          isHeader
          iconName={<i className={'eva eva-bell-outline'}/>}
          link="/admin/notifications"
          index="notifications"
        /> */
          // <LinksGroup
          //   onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          //   activeItem={props.activeItem}
          //   header="UI Elements"
          //   isHeader
          //   iconName={<i className={'eva eva-cube-outline'}/>}
          //   link="/admin/uielements"
          //   index="uielements"
          //   childrenLinks={[
          //     {
          //       header: 'Charts', link: '/admin/ui-elements/charts',
          //     },
          //     {
          //       header: 'Icons', link: '/admin/ui-elements/icons',
          //     },
          //     {
          //       header: 'Google Maps', link: '/admin/ui-elements/maps',
          //     },
          //   ]}
          // />
        }
      </ul>
    </nav>
  );
};

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
