import api from "../api";

export const createCategoryService = async (bank) => {
  return await api.post("/api/v1/category", bank);
};

export const updateCategoryService = async (id, bank) => {
  return await api.put(`/api/v1/category/${id}`, bank);
};

export const listCategoryService = async (filter) => {
  return await api.get("/api/v1/category", {
    params: filter,
  });
};

export const getByIdCategoryService = async (id) => {
  return await api.get(`/api/v1/category/${id}`);
};

export const inactiveActiveCategoryService = async (id) => {
  return await api.put(`/api/v1/category/active-inactive/${id}`);
};
