import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  CardFooter,
  Button,
  Form,
} from "reactstrap";
import Swal from "sweetalert2";
import getBase64 from "../../utils/input-base64";
import {
  createBannerHomeService,
  getByIdBannerHomeService,
  updateBannerHomeService,
} from "../../services/Register/BannerHomeService";
import colors from "../../utils/colors";

const BannerRegisterView = () => {
  const history = useHistory();
  const params = useParams();
  const [register, setRegister] = useState({
    id: null,
    title: null,
    description: null,
    textBtn: null,
    typeBg: null,
    typeContent: null,
    size: null,
    hatTag: null,
    square: null,
    imgMain: null,
  });

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (!params?.id || params?.id <= 0) {
      handleInsert();
    } else {
      handleUpdate();
    }
  };

  const handleInsert = () => {
    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    createBannerHomeService(register)
      .then(({ data }) => {
        setRegister({
          id: null,
          title: null,
          description: null,
          textBtn: null,
          typeBg: null,
          typeContent: null,
          size: null,
          hatTag: null,
          square: null,
          imgMain: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/banner");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleUpdate = () => {
    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    updateBannerHomeService(register.id, register)
      .then(({ data }) => {
        setRegister({
          id: null,
          title: null,
          description: null,
          textBtn: null,
          typeBg: null,
          typeContent: null,
          size: null,
          hatTag: null,
          square: null,
          imgMain: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/banner");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetBank = () => {
    if (!params?.id || params?.id <= 0) {
      return;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getByIdBannerHomeService(params?.id)
      .then(({ data }) => {
        Swal.close();
        setRegister({
          ...data.resultSet,
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleGetBank();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card className="border-0 shadow">
          <CardHeader className="border-0 bg-white">
            <span className="text-danger float-right">
              Os campos com * são obrigatórios
            </span>
            <h3>Banner</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="title">
                    Titulo <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    value={register.title}
                    onChange={(ev) => {
                      setRegister({ ...register, title: ev.target.value });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="text">
                    Descrição <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    id="text"
                    name="text"
                    className="form-control"
                    value={register.description}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        description: ev.target.value,
                      });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <Card className="border-0 shadow mb-4">
                  <CardHeader className="border-0 bg-white">
                    <h5>Configuração</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label for="title">
                            Titulo botão <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            id="textBtn"
                            name="textBtn"
                            value={register.textBtn}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                textBtn: ev.target.value,
                              });
                            }}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label for="title">
                            Tag <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            id="hatTag"
                            name="hatTag"
                            value={register.hatTag}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                hatTag: ev.target.value,
                              });
                            }}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label for="title">
                            Tipo botão <span className="text-danger">*</span>
                          </Label>
                          <select
                            id="typeBg"
                            name="typeBg"
                            className="form-control"
                            value={register.typeBg}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                typeBg: ev.target.value,
                              });
                            }}
                            required
                          >
                            <option value="">Selecione</option>
                            {colors.map((color, key) => (
                              <option key={key} value={color}>
                                {color}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label for="title">
                            Tipo Conteúdo <span className="text-danger">*</span>
                          </Label>
                          <select
                            className="form-control"
                            value={register.typeContent}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                typeContent: ev.target.value,
                              });
                            }}
                            required
                          >
                            <option value="">Selecione</option>
                            <option value={"default-01"}>default-01</option>
                            <option value={"dark-01"}>dark-01</option>
                            <option value={"light-01"}>light-01</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label for="title">
                            Tamanho <span className="text-danger">*</span>
                          </Label>
                          <select
                            className="form-control"
                            value={register.size}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                size: ev.target.value,
                              });
                            }}
                            required
                          >
                            <option value="">Selecione</option>
                            <option value={"md"}>md</option>
                            <option value={"lg"}>lg</option>
                            <option value={"xs"}>xs</option>
                            <option value={"sm"}>sm</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label for="title">
                            Square <span className="text-danger">*</span>
                          </Label>
                          <select
                            className="form-control"
                            value={register.square}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                square: ev.target.value,
                              });
                            }}
                            required
                          >
                            <option value="">Selecione</option>
                            <option value={"01"}>01</option>
                            <option value={"02"}>02</option>
                            <option value={"03"}>03</option>
                            <option value={"04"}>04</option>
                            <option value={"05"}>05</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12">
                <Card className="border-0 shadow">
                  <CardHeader className="border-0 bg-white">
                    <h5>Upload</h5>
                  </CardHeader>
                  <CardBody>
                    <Col md="12">
                      {register?.imgMain && (
                        <div className="p-4 rounded border border-1 mb-4">
                          <img
                            id="img"
                            height="100"
                            alt="Imagem"
                            src={register.imgMain}
                          />
                        </div>
                      )}
                      <FormGroup>
                        <Label for="name">
                          Imagem destaque <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="file"
                          id="imgMain"
                          name="imgMain"
                          accept="image/*"
                          onChange={(ev) => {
                            const file = ev.target.files[0] || null;
                            getBase64(file).then((result) => {
                              setRegister({
                                ...register,
                                imgMain: result.base64,
                              });
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" className="mr-2">
              SALVAR
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push("/admin/banner")}
            >
              VOLTAR
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
};

export default BannerRegisterView;
