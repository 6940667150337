// -- React and related libs
import React from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";

// -- Third Party Libs
import PropTypes from "prop-types";

// -- Custom Components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import Dashboard from "../../pages/dashboard/Dashboard";
import Typography from "../../pages/typography/Typography";
import Notifications from "../../pages/notifications/Notifications";
import Tables from "../../pages/tables/Tables";
import Charts from "../../pages/uielements/charts/Charts";
import Icons from "../../pages/uielements/icons/IconsPage";
import Maps from "../../pages/uielements/maps/google/GoogleMapPage";

// -- Component Styles
import s from "./Layout.module.scss";
import BranchListView from "../../pages/branch/BranchListView";
import BranchRegisterView from "../../pages/branch/BranchRegisterView";
import BankListView from "../../pages/bank/BankListView";
import BankRegisterView from "../../pages/bank/BankRegisterView";
import UserListView from "../../pages/user/UserListView";
import UserRegisterView from "../../pages/user/UserRegisterView";
import BenefitListView from "../../pages/benefit/BenefitListView";
import BenefitRegisterView from "../../pages/benefit/BenefitRegisterView";
import TokenApiView from "../../pages/tokenApi/TokenApiView";
import NoteOficialListView from "../../pages/NoteOficial/NoteOficialListView";
import NoteOficialRegisterView from "../../pages/NoteOficial/NoteOficialRegisterView";
import BannerRegisterView from "../../pages/dashboard/BannerRegisterView";
import QuestionListView from "../../pages/question/QuestionListView";
import QuestionRegisterView from "../../pages/question/QuestionRegisterView";
import ProductListView from "../../pages/product/ProductListView";
import ProductRegisterView from "../../pages/product/ProductRegisterView";
import CategoryListView from "../../pages/category/CategoryListView";
import CategoryRegisterView from "../../pages/category/CategoryRegisterView";

const Layout = (props) => {
  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <Breadcrumbs url={props.location.pathname} />
          <Switch>
            <Route
              path="/"
              exact
              render={() => <Redirect to="admin/banner" />}
            />
            <Route path="/admin/banner" exact component={Dashboard} />
            <Route
              path="/admin/banner/novo"
              exact
              component={BannerRegisterView}
            />
            <Route
              path="/admin/banner/:id"
              exact
              component={BannerRegisterView}
            />
            <Route path="/admin/filial" exact component={BranchListView} />
            <Route
              path="/admin/filial/novo"
              exact
              component={BranchRegisterView}
            />
            <Route
              path="/admin/filial/:id"
              exact
              component={BranchRegisterView}
            />
            <Route path="/admin/financeira" exact component={BankListView} />
            <Route
              path="/admin/financeira/novo"
              exact
              component={BankRegisterView}
            />
            <Route
              path="/admin/financeira/:id"
              exact
              component={BankRegisterView}
            />
            <Route
              path="/admin/usuario-sistema"
              exact
              component={UserListView}
            />
            <Route
              path="/admin/usuario-sistema/novo"
              exact
              component={UserRegisterView}
            />
            <Route
              path="/admin/usuario-sistema/:id"
              exact
              component={UserRegisterView}
            />
            <Route path="/admin/produto" exact component={ProductListView} />
            <Route
              path="/admin/produto/novo"
              exact
              component={ProductRegisterView}
            />
            <Route
              path="/admin/produto/:id"
              exact
              component={ProductRegisterView}
            />
            <Route path="/admin/categoria" exact component={CategoryListView} />
            <Route
              path="/admin/categoria/novo"
              exact
              component={CategoryRegisterView}
            />
            <Route
              path="/admin/categoria/:id"
              exact
              component={CategoryRegisterView}
            />
            <Route path="/admin/vantagem" exact component={BenefitListView} />
            <Route
              path="/admin/vantagem/novo"
              exact
              component={BenefitRegisterView}
            />
            <Route
              path="/admin/vantagem/:id"
              exact
              component={BenefitRegisterView}
            />
            <Route path="/admin/nota-oficial" exact component={NoteOficialListView} />
            <Route
              path="/admin/nota-oficial/novo"
              exact
              component={NoteOficialRegisterView}
            />
            <Route
              path="/admin/nota-oficial/:id"
              exact
              component={NoteOficialRegisterView}
            />
            <Route path="/admin/duvida" exact component={QuestionListView} />
            <Route
              path="/admin/duvida/novo"
              exact
              component={QuestionRegisterView}
            />
            <Route
              path="/admin/duvida/:id"
              exact
              component={QuestionRegisterView}
            />
            <Route path="/admin/api" exact component={TokenApiView} />
            {/* <Route path="/admin/chat" exact component={Chat}/> */}
            <Route path="/admin/typography" exact component={Typography} />
            <Route path="/admin/tables" exact component={Tables} />
            <Route
              path="/admin/notifications"
              exact
              component={Notifications}
            />
            <Route
              path="/admin/ui-elements"
              exact
              render={() => <Redirect to={"/template/ui-elements/charts"} />}
            />
            <Route path="/admin/ui-elements/charts" exact component={Charts} />
            <Route path="/admin/ui-elements/icons" exact component={Icons} />
            <Route path="/admin/ui-elements/maps" exact component={Maps} />
            <Route path="*" exact render={() => <Redirect to="/error" />} />
          </Switch>
        </main>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
