import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  CardFooter,
  Button,
  Form,
} from "reactstrap";
import {
  createBankService,
  getByIdBankService,
  updateBankService,
} from "../../services/Register/BankService";
import Swal from "sweetalert2";
import getBase64 from "../../utils/input-base64";
import { createNoteOficialService, getByIdNoteOficialService, updateNoteOficialService } from "../../services/Register/NoteOficialService";

const NoteOficialRegisterView = () => {
  const history = useHistory();
  const params = useParams();
  const [register, setRegister] = useState({
    id: null,
    title: null,
    text: null,
    img: null,
  });

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (!params?.id || params?.id <= 0) {
      handleInsert();
    } else {
      handleUpdate();
    }
  };

  const handleInsert = () => {
    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    createNoteOficialService(register)
      .then(({ data }) => {
        setRegister({
          id: null,
          title: null,
          text: null,
          img: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/nota-oficial");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleUpdate = () => {
    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    updateNoteOficialService(register.id, register)
      .then(({ data }) => {
        setRegister({
          id: null,
          title: null,
          text: null,
          img: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/nota-oficial");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetBank = () => {
    if (!params?.id || params?.id <= 0) {
      return;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getByIdNoteOficialService(params?.id)
      .then(({ data }) => {
        Swal.close();
        setRegister({
          ...data.resultSet,
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleGetBank();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card className="border-0 shadow">
          <CardHeader className="border-0 bg-white">
            <span className="text-danger float-right">
              Os campos com * são obrigatórios
            </span>
            <h3>Nota Oficial</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="title">
                    Titulo <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    value={register.title}
                    onChange={(ev) => {
                      setRegister({ ...register, title: ev.target.value });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="text">
                    Descrição <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    id="text"
                    name="text"
                    className="form-control"
                    value={register.text}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        text: ev.target.value,
                      });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <Card className="border-0 shadow">
                  <CardHeader className="border-0 bg-white">
                    <h5>Upload</h5>
                  </CardHeader>
                  <CardBody>
                    <Col md="12">
                      {register?.img && (
                        <div className="p-4 rounded border border-1 mb-4">
                          <img
                            id="img"
                            height="100"
                            alt="Imagem"
                            src={register.img}
                          />
                        </div>
                      )}
                      <FormGroup>
                        <Label for="name">
                          Imagem destaque <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="file"
                          id="img"
                          name="img"
                          accept="image/*"
                          onChange={(ev) => {
                            const file = ev.target.files[0] || null;
                            getBase64(file).then((result) => {
                              setRegister({
                                ...register,
                                img: result.base64,
                              });
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" className="mr-2">
              SALVAR
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push("/admin/nota-oficial")}
            >
              VOLTAR
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
};

export default NoteOficialRegisterView;
