import api from "../api";

export const createUserService = async (bank) => {
  return await api.post("/api/v1/user", bank);
};

export const updateUserService = async (id, bank) => {
  return await api.put(`/api/v1/user/${id}`, bank);
};

export const listUserService = async (filter) => {
  return await api.get("/api/v1/user", {
    params: filter,
  });
};

export const getByIdUserService = async (id) => {
  return await api.get(`/api/v1/user/${id}`);
};

export const inactiveActiveUserService = async (id) => {
  return await api.put(`/api/v1/user/active-inactive/${id}`);
};
