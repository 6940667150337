import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { inactiveActiveQuestionService, listQuestionService } from "../../services/Register/QuestionService";

const QuestionListView = () => {
  const history = useHistory();
  const [filter, setFilter] = useState({
    name: null,
    phone: null,
    state: null,
  });
  const [list, setList] = useState([]);

  const handleList = () => {
    setList([]);
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    listQuestionService(filter)
      .then(({ data }) => {
        Swal.close();
        setList(data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleUpdate = (id) => {
    history.push(`/admin/duvida/${id}`);
  };

  const handleActiveInactive = (id) => {
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    inactiveActiveQuestionService(id)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro alterado com sucesso",
        });

        handleList();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <div>
        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            <Card className="border-0 mb-5 mt-3 shadow">
              <CardHeader className="border-0 bg-white">
                <h4 className="headline-2">FILTRO - DÚVIDA</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Titulo</Label>
                      <Input
                        type="text"
                        onChange={(ev) =>
                          setFilter({ ...filter, title: ev.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="border-0 bg-white">
                <Button className="mr-2" color="default" onClick={handleList}>
                  BUSCAR
                </Button>
                <Button
                  color="success"
                  onClick={() => history.push("/admin/duvida/novo")}
                >
                  NOVO REGISTRO
                </Button>
              </CardFooter>
            </Card>
            {list.length <= 0 && (
              <Alert color="primary" className="text-primary">
                Não foram identificados registros para esta consulta
              </Alert>
            )}
            {list.length > 0 && (
              <Card className="shadow">
                <CardHeader className="border-0 bg-white">
                  Lista de dÚvidas
                </CardHeader>
                <Table
                  className={`table-striped table-borderless table-hover`}
                  responsive
                >
                  <thead>
                    <tr>
                      <th>DATA CADASTRO</th>
                      <th>NOME</th>
                      <th>URL</th>
                      <th>OPÇÕES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((register, key) => (
                      <tr key={key}>
                        <td>{register.createdAt}</td>
                        <td>{register.title}</td>
                        <td>{register.path}</td>
                        <td>
                          <Button
                            color="warning"
                            onClick={() => handleUpdate(register.id)}
                          >
                            ALTERAR
                          </Button>
                          {` `}
                          <Button
                            color={register.active > 0 ? "danger" : "success"}
                            onClick={() => {
                              handleActiveInactive(register.id);
                            }}
                          >
                            {register.active > 0 ? "INATIVAR" : "ATIVAR"}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default QuestionListView;
