import api from "../api";

export const createNoteOficialService = async (data) => {
  return await api.post("/api/v1/note-oficial", data);
};

export const updateNoteOficialService = async (id, data) => {
  return await api.put(`/api/v1/note-oficial/${id}`, data);
};

export const listNoteOficialService = async (data) => {
  return await api.get("/api/v1/note-oficial", {
    params: data,
  });
};

export const getByIdNoteOficialService = async (id) => {
  return await api.get(`/api/v1/note-oficial/${id}`);
};

export const inactiveActiveNoteOficialService = async (id) => {
  return await api.put(`/api/v1/note-oficial/active-inactive/${id}`);
};
