import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import Footer from "../../components/Footer/Footer";
import { loginUser, LOGIN_SUCCESS } from "../../actions/auth";
import hasToken from "../../services/authService";

import loginImage from "../../assets/loginImage.svg";
import SofiaLogo from "../../components/Icons/SofiaLogo.js";
import { Auth } from "../../services/Auth";
import Swal from "sweetalert2";

const Login = (props) => {

  const [state, setState] = useState({
    email: '',
    password: '',
  })

  const doLogin = async (e) => {
    e.preventDefault();
    
    Swal.fire({
      title: 'Realizando login',
      html: 'Por favor, aguarde ... ',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    await Auth(state.email, state.password).then((response) => {   
      localStorage.setItem('authenticated_bevi', true);
      localStorage.setItem('token_bevi_xpto', response?.data?.resultSet?.jwt);
      localStorage.setItem('data_user_bevi_app', JSON.stringify(response?.data?.resultSet?.user));

      props.dispatch({
        type: LOGIN_SUCCESS
      });
      Swal.close();
    }).catch((e) => {
      console.log(e);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text : 'Não foi possível realizar login, verifique os dados de acesso'
      })
    });
  }

  const changeCreds = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const { from } = props.location.state || { from: { pathname: '/admin' }};
  if (hasToken(JSON.parse(localStorage.getItem('authenticated_bevi')))) {
    return (
      <Redirect to={from} />
    )
  }

  return (
    <div className="auth-page">
      <Container className="col-12">
        <Row className="d-flex align-items-center">
          <Col xs={12} lg={6} className="left-column">
            <Widget className="widget-auth widget-p-lg">
              <div className="d-flex align-items-center justify-content-between py-3">
                <p className="auth-header mb-0">Login</p>
              </div>
              <form onSubmit={(event) => doLogin(event)}>
                <FormGroup className="my-3">
                  <FormText>Email</FormText>
                  <Input
                    id="email"
                    className="input-transparent pl-3"
                    value={state.email}
                    onChange={(event) => changeCreds(event)}
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                  />
                </FormGroup>
                <FormGroup  className="my-3">
                  <div className="d-flex justify-content-between">
                    <FormText>Senha</FormText>
                    {/* <Link to="/error">Forgot password?</Link> */}
                  </div>
                  <Input
                    id="password"
                    className="input-transparent pl-3"
                    value={state.password}
                    onChange={(event) => changeCreds(event)}
                    type="password"
                    required
                    name="password"
                    placeholder="Password"
                  />
                </FormGroup>
                <div className="bg-widget d-flex justify-content-center">
                  <Button className="rounded-pill my-3" type="submit" color="default">Login</Button>
                </div>
              </form>
            </Widget>
          </Col>
          <Col xs={0} lg={6} className="right-column">
            <div>
              <img src={loginImage} alt="Error page" width="100%" />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}


Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
