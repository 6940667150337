import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  CardFooter,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Swal from "sweetalert2";
import Select from "react-select";
import { listBankService } from "../../services/Register/BankService";
import { listBenefitService } from "../../services/Register/BenefitService";
import getBase64 from "../../utils/input-base64";
import {
  createCategoryService,
  getByIdCategoryService,
  updateCategoryService,
} from "../../services/Register/CategoryService";
import FormBenefitComponentView from "../benefit/component/FormBenefitComponentView";

const CategoryRegisterView = () => {
  const history = useHistory();
  const params = useParams();
  const [register, setRegister] = useState({
    id: null,
    name: null,
    path: null,
    description: null,
    whatIsDescription: null,
    forWhomDescription: null,
    banks: [],
    benefits: [],
  });
  const [banks, setBanks] = useState([]);
  const [benefits, setBenefits] = useState([]);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleInsert();
    handleUpdate();
  };

  const handleUpdate = () => {
    if (!params?.id || params?.id <= 0) return;
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    updateCategoryService(register.id, register)
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          path: null,
          description: null,
          whatIsDescription: null,
          forWhomDescription: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/categoria");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleInsert = () => {
    if (params?.id && params?.id > 0) return;

    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    createCategoryService(register)
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          path: null,
          description: null,
          whatIsDescription: null,
          forWhomDescription: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/categoria");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetById = () => {
    if (!params?.id || params?.id <= 0) {
      return;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getByIdCategoryService(params?.id)
      .then(({ data }) => {
        Swal.close();
        setRegister(data.resultSet);
        handleListBank();
        handleListBenefits();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleListBank = () => {
    listBankService().then(({ data }) => {
      if (!data?.resultSet) return;
      setBanks(
        data?.resultSet.map((register) => {
          return {
            label: register.name,
            value: register.id,
          };
        })
      );
    });
  };

  const handleListBenefits = () => {
    listBenefitService().then(({ data }) => {
      if (!data?.resultSet) return;
      setBenefits(
        data?.resultSet.map((register) => {
          return {
            label: register.title,
            value: register.id,
          };
        })
      );
    });
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    handleGetById();
    if (!params?.id) {
      handleListBank();
      handleListBenefits();
    }
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card className="border-0 shadow">
          <CardHeader className="border-0 bg-white">
            <span className="text-danger float-right">
              Os campos com * são obrigatórios
            </span>
            <h3>Categoria</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="name">
                    Nome <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="nome"
                    name="nome"
                    value={register.name}
                    onChange={(ev) => {
                      let path = ev.target.value.toLowerCase();
                      path = path
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "");
                      path = path.replace(/[^a-zA-Z ]/g, "");
                      path = path.replace(/[ ]/g, "-");
                      setRegister({
                        ...register,
                        name: ev.target.value,
                        path: path,
                      });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="path">
                    URL <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="path"
                    name="path"
                    value={register.path}
                    onChange={(ev) => {
                      setRegister({ ...register, path: ev.target.value });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="path">
                    Financeira <span className="text-danger">*</span>
                  </Label>
                  <Select
                    value={register?.banks}
                    isMulti
                    name="bank"
                    options={banks}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(ev) => {
                      setRegister({ ...register, banks: ev });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="path">Vantagens</Label>
                  <Row>
                    <Col md="9">
                      <Select
                        value={register?.benefits}
                        isMulti
                        name="benefits"
                        options={benefits}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(ev) => {
                          setRegister({ ...register, benefits: ev });
                        }}
                      />
                    </Col>
                    <Col md="3">
                      <Button
                        color="success"
                        className="w-100"
                        onClick={() => toggle()}
                      >
                        Adicionar vantagem
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="description">
                    Descrição <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    className="form-control"
                    type="text"
                    id="description"
                    name="description"
                    value={register.description}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        description: ev.target.value,
                      });
                    }}
                    required
                  ></textarea>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="whatIsDescription">
                    O que é <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    className="form-control"
                    type="text"
                    id="whatIsDescription"
                    name="whatIsDescription"
                    value={register.whatIsDescription}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        whatIsDescription: ev.target.value,
                      });
                    }}
                    required
                  ></textarea>
                </FormGroup>
              </Col>
              {/* <Col md="12">
                <FormGroup>
                  <Label for="forWhomDescription">Para quem é</Label>
                  <textarea
                    className="form-control"
                    type="text"
                    id="forWhomDescription"
                    name="forWhomDescription"
                    value={register.forWhomDescription}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        forWhomDescription: ev.target.value,
                      });
                    }}
                    required
                  ></textarea>
                </FormGroup>
              </Col> */}
              <Col md="12">
                <Card className="border-0 shadow">
                  <CardHeader className="border-0 bg-white">
                    <h5>Upload</h5>
                  </CardHeader>
                  <CardBody>
                    <Col md="12">
                      <FormGroup>
                        <Label for="name">Imagem Principal</Label>
                        {register?.imgMain && (
                          <div className="p-4 rounded border border-1 mb-4">
                            <img
                              id="img"
                              height="100"
                              alt="logo-financeira"
                              src={register.imgMain}
                            />
                          </div>
                        )}
                        <Input
                          type="file"
                          id="nome"
                          name="nome"
                          accept="image/*"
                          onChange={(ev) => {
                            const file = ev.target.files[0] || null;
                            getBase64(file).then((result) => {
                              setRegister({
                                ...register,
                                imgMain: result.base64,
                              });
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col md="12">
                      <FormGroup>
                        <Label for="name">Background</Label>
                        {register?.imgBg && (
                          <div className="p-4 rounded border border-1 mb-4">
                            <img
                              id="img"
                              height="100"
                              alt="logo-financeira"
                              src={register.imgBg}
                            />
                          </div>
                        )}
                        <Input
                          type="file"
                          id="nome"
                          name="nome"
                          accept="image/*"
                          onChange={(ev) => {
                            const file = ev.target.files[0] || null;
                            getBase64(file).then((result) => {
                              setRegister({
                                ...register,
                                imgBg: result.base64,
                              });
                            });
                          }}
                        />
                      </FormGroup>
                    </Col> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" className="mr-2">
              SALVAR
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push("/admin/categoria")}
            >
              VOLTAR
            </Button>
          </CardFooter>
        </Card>
      </Form>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Cadastrar</ModalHeader>
        <ModalBody>
          <FormBenefitComponentView
            isModal={true}
            handleActionAfterSave={() => {
              handleListBenefits();
              toggle();
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default CategoryRegisterView;
